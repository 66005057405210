import * as React from "react";
import { styled } from "@material-ui/styles";
import { Icon } from "@iconify/react";
import cubeIcon from "@iconify-icons/carbon/cube";
import {
  Typography,
  Box,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
} from "@material-ui/core";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Box
            component={Icon}
            icon={cubeIcon}
            sx={{ display: "block", width: 24, height: 24 }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs({
  title,
  open,
  handleClose,
  children,
}) {
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        fullScreen
      >
        {/* <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {title}
        </BootstrapDialogTitle> */}
        <DialogContent dividers>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 28,
              top: 8,
              //   color: (theme) => theme.palette.grey[500],
              color: "black",
              xIndex: 100000,
            }}
          >
            <Box
              component={Icon}
              icon={cubeIcon}
              sx={{ display: "block", width: 24, height: 24 }}
            />
          </IconButton>

          {children}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
