import * as React from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/styles";
import { SvgIcon } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   foo: index => {
//     width: 250,
//     position: "relative",
//     left: index,
//   },
// }));

const useStyles = makeStyles((theme) => ({
  foo: (index) => ({
    position: "relative",
    left: -index * 30,
    top: index % 2 == 0 ? index + 80 : index - 80,
    // bottom: index % 2 == 0 ? index + 100 : index - 100,
    animation: "$cardmov 20s infinite",

    "&:hover": {
      zIndex: 100,
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  }),
  boo: {
    // animation: "$cardmov 10000s",
  },
  "@keyframes cardmov": {
    "10%": {
      // top: (index) => index * 20,
      transform: "rotate(3  deg)",
    },
    "50%": {
      top: (index) => (index % 2 == 0 ? index - 80 : index + 80),
      transform: "rotate(300deg)",
    },
  },
}));

// const useStyles = makeStyles({
//   foo: (index) => ({
//     position: "relative",
//     left: -index * 90,
//   }),
// });

export default function ServiceCard({ icon, title, image, index }) {
  const classes = useStyles(index);
  return (
    <Card className={`${classes.foo} ${classes.boo}`}>
      <Box
        sx={{
          width: 250,
          height: 130,
          pt: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Icon color="secondary" sx={{ width: "100%", height: "100%", color: "white", fill: "white" }}>
          <img style={{ height: "100%", width: "100%", color: "white" }} src={image} title={title} />
        </Icon>
      </Box>
      <Box sx={{ mt: 3, pl: 3.5, pr: 2.5, pb: 2 }}>
        <Typography gutterBottom variant="h5" component="div" align="center">
          {title}
        </Typography>
      </Box>
    </Card>
  );
}
