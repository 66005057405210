import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Grid,
  Divider,
  Typography,
  Container,
  CardMedia,
} from "@material-ui/core";

import Degree from "../../assets/images/grad.jpg";
import Icpc from "../../assets/images/icpc.jpg";
import Hrm from "../../assets/images/hrm.jpg";
import Hobby from "../../assets/images/hobby.png";
import Team from "../../assets/images/team.png";
import Ota from "../../assets/images/ota.png";
import Judge from "../../assets/images/judge.png";
import Code from "../../assets/images/code.png";
import Rubix from "../../assets/images/rubix.jpg";

import PortfolioCard from "../../components/surfaces/PortfolioCard";
import DetailCard from "../../components/surfaces/DetailCard";
import CustomizedDialogs from "../../components/surfaces/Dialog";
import ImageGrid from "../../components/surfaces/ImageGrid";
const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(8),
    height: theme.spacing(100),
  },
  imgContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  img: {
    margin: theme.spacing(0.2),
  },
}));
const itemData = [
  {
    img: Degree,
    title: "Educational Background",
    cols: 2,
    rows: 3,
  },
  {
    img: Hobby,
    title: "Intersts and hobby",
    cols: 1,
    rows: 3,
  },
  {
    img: Icpc,
    title: "Certificates and Achievments",
    cols: 1,
    rows: 6,
  },
  {
    img: Rubix,
    title: "Intersts and hobby",
    cols: 1,
    rows: 3,
  },
  {
    img: Team,
    title: "ICPC Ethiopian National Champion",
    cols: 2,
    rows: 3,
  },
  {
    img: Judge,
    title: "Online Competitive Programming Judge",
    cols: 1,
    rows: 3,
  },
  {
    img: Hrm,
    title: "Human Resource managment System",
    cols: 1,
    rows: 3,
  },
  {
    img: Ota,
    title: "Online Travel Agency System",
    cols: 2,
    rows: 3,
  },
];
const portfolio_thumbnail = [
  {
    image: Degree,
    width: 250,
    height: 300,
    opacity: 0.4,
    title: "Degree Detail",
    desc: "BSc Degree in Computer Science and Engineering",
    detail: {
      image: Degree,
      title: "Degree",
      desc: "desc",
    },
  },
  {
    image: Icpc,
    width: 350,
    height: 300,
    opacity: 0.4,
    title: "Certificates and achievments",
    desc: "BSc Degree in Computer Science and Engineering",
    detail: {
      image: Icpc,
      title: "Certificates and achievments",
      desc: "",
    },
  },
  {
    image: Hobby,
    width: 300,
    height: 300,
    opacity: 0.4,
    title: "HRM System",
    desc: "A human resource management build with django framework",
    detail: {
      image: Hrm,
      title: "HRM System",
      desc: "",
    },
  },
];

export default function Portfolio() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [detail, setDetail] = React.useState(null);

  const handleClickOpen = (detail) => {
    setOpen(true);
    setDetail(detail);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <Divider sx={{ mb: 5 }}>
        <Typography variant="h3" align="center">
          Portfolio
        </Typography>
        <Typography variant="subtitle2" align="center" color="textSecondary">
          Previous works
        </Typography>
      </Divider>
      <CustomizedDialogs
        title={detail && detail.title}
        open={open}
        handleClose={handleClose}
      >
        <DetailCard item={detail} />
      </CustomizedDialogs>
      <div style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}>
        <ImageGrid itemData={itemData} />
        {/* {portfolio_thumbnail.map((item, ind) => (
          <div key={ind} style={{ margin: "4px", display: "flex" }}>
            <PortfolioCard item={item} handleClick={handleClickOpen} />
          </div>
        ))} */}
      </div>
      {/* <Box className={classes.imgContainer}>
        {[1, 2, 3, 4, 5].map((item) => (
          <CardMedia
            className={classes.img}
            image={Amir}
            sx={{
              m: 0.5,
              width: `${Math.floor(Math.random() * 5) * 10}%`,
              height: 200,
            }}
          />
        ))}
      </Box> */}
    </div>
  );
}
