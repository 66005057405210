import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Divider, Typography, Container } from "@material-ui/core";

import SkillAccordion from "../../components/surfaces/SkillAccordion";
import { SKILLS } from "../../utils/skills";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(8),
    height: theme.spacing(100),
  },
}));

export default function Skills() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Divider sx={{ mb: 10 }}>
        <Typography variant="h3" align="center">
          Skills
        </Typography>
        <Typography variant="subtitle2" align="center" color="textSecondary">
          Technical skills
        </Typography>
      </Divider>
      <Container maxWidth="md">
        <Grid container>
          {SKILLS.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              key={item.title + index}
              sx={{ mb: 2 }}
            >
              <SkillAccordion
                title={item.title}
                years={item.years}
                details={item.details}
                icon={item.icon}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}
