import React from "react";
import {
  Grid,
  Typography,
  Paper,
  Divider,
  Box,
  CardMedia,
  Container,
  Stack,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Icon } from "@iconify/react";
import downloadIcon from "@iconify-icons/carbon/download";

import Amir from "../../assets/images/Amir2.jpg";
const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(100),
    paddingLeft: theme.spacing(8),
    // backgroundColor: "red",
  },
  "@keyframes elevation": {
    "100%": {
      boxShadow:
        "0px 8px 10px -5px rgba(145, 158, 171, 0.2),0px 16px 24px 2px rgba(145, 158, 171, 0.14),0px 6px 30px 5px rgba(145, 158, 171, 0.12)",
    },
  },
  imgContainer: {
    animation: "$elevation 5s ",
  },
  descContainer: {
    animation: "$elevation 5s ",
  },
  profileImg: {
    borderRadius: "inherit",
    borderRadius: 20,
  },
}));

function Description({ label, value }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: "100%" }}
    >
      <Typography
        variant="body2"
        display="inline"
        sx={{ ml: 1, fontWeight: "bolder" }}
      >
        {label}
      </Typography>
      <Typography variant="body2" sx={{ ml: 2 }}>
        {value}
      </Typography>
    </Stack>
  );
}

function ExperienceCard({ year, title }) {
  return (
    <Paper elevation={3} sx={{ p: 2, display: "flex", alignItems: "center" }}>
      <Typography variant="h4" display="inline">
        {year}
      </Typography>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        display="inline"
        sx={{ ml: 1 }}
      >
        {" "}
        {title}
      </Typography>
    </Paper>
  );
}

export default function About() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Divider sx={{ mb: 2 }}>
        <Typography variant="h3" align="center">
          About Me
        </Typography>
        <Typography variant="subtitle2" align="center" color="textSecondary">
          My Introduction
        </Typography>
      </Divider>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: 2 }}>
              <Paper
                className={classes.imgContainer}
                elevation={0}
                sx={{ borderRadius: 0, height: 350, p: 2 }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{
                      p: 1,
                      display: "flex",
                      height: 300,
                      alignItems: "center",
                    }}
                  >
                    <CardMedia
                      className={classes.profileImg}
                      image={Amir}
                      sx={{ width: "100%", height: 190 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ display: "flex" }}
                  >
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ height: "100%", ml: 1, mr: 1 }}
                    ></Divider>
                    <Stack
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                      sx={{ width: "100%" }}
                    >
                      <Description label="Name" value="Amir Kheiru" />
                      <Description label="Nationality" value="Ethiopian" />
                      <Description label="Education" value="Bsc" />
                      <Description label="Major " value="Computer Science " />
                      <Description
                        label="2nd Major "
                        value="Electronics Eng."
                      />
                      <Description
                        label="Award"
                        value="Very Great Distinction"
                      />
                      <Description
                        label="Activity"
                        value="Competitive Programmer"
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: 2 }}>
              <Paper
                elevation={3}
                className={classes.descContainer}
                sx={{
                  borderRadius: 0,
                  height: 350,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ m: 0.5, pt: 4, width: "80%", height: 350 }}>
                  <Typography variant="body1">
                    My name is{" "}
                    <Typography variant="span" color="primary">
                      Amir
                    </Typography>{" "}
                    and I am a fullstack developer and COMPETITIVE PROGRAMMER. I
                    have won several awards in competitive programing contest. I
                    am an ICPC world finalist. I have also participated in
                    complex projects including OTA(online travel agency) which
                    allows users to search, find and book hotels, flights,
                    restaurants and so on. I have also developed and
                    decentralized online judge for my university which allows to
                    held programming contests. Coding and problem Solving is my
                    passion. I love challengs
                  </Typography>
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mt: 3, ml: 2, mr: 2 }}
        >
          <ExperienceCard year="+2" title="Years Experience" />
          <ExperienceCard year="+7" title="Completed Projects" />
          <ExperienceCard year="+3" title="Companies Worked" />
          {/* <Box sx={{ p: 2, display: "flex", alignItems: "center" }}> */}
          <Button endIcon={<Icon icon={downloadIcon} component={Paper} />}>
            Download CV
          </Button>
          {/* </Box> */}
        </Stack>
      </Container>
    </div>
  );
}
