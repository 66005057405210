import * as React from "react";
import { styled } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { Box, Stack, Paper } from "@material-ui/core";
import LinearProgress, {
  linearProgressClasses,
} from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { Icon } from "@iconify/react";
import chevronDown from "@iconify-icons/carbon/chevron-down";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    // backgroundColor:
    //   theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    // backgroundColor:
    //   theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    // backgroundColor:
    //   theme.palette.grey[theme.palette.mode === "light" ? 600 : 200],
  },
}));

export default function SkillAccordion({ title, years, icon, details }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Paper elevation={3} sx={{ mr: 2 }}>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<Icon icon={chevronDown} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box
            sx={{
              p: 1,
              mr: 1,
              color: "primary.main",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon color="primary" icon={icon} width={24} height={24} />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{
                width: "100%",
                flexShrink: 0,
                fontWeight: "bold",
                lineHeight: "18px",
              }}
              variant="subtitle1"
            >
              {title}
            </Typography>
            <Typography sx={{ color: "text.secondary" }} variant="body2">
              {years}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: "80%" }}>
            {details.map((item, index) => (
              <Box sx={{ mb: 2 }} key={item.name + index}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mb: 0.5 }}
                >
                  <Typography variant="body2" fontWeight="600">
                    {item.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {item.value}%
                  </Typography>
                </Stack>
                <BorderLinearProgress
                  value={item.value}
                  variant="determinate"
                />
              </Box>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}
