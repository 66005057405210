import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";

import Amir from "../../assets/images/degree.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: "black",
  },
  desc: {
    // padding: theme.spacing(2),
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    // left: "2%",
  },
  title: {
    color: "white",
  },
  more: {
    color: "white",
  },
}));

export default function PortfolioCard({ item, handleClick }) {
  const classes = useStyles();
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <CardActionArea onClick={() => handleClick(item.detail)}>
        <div
          style={{
            display: "inline-block",
            borderRadius: "10px",
            backgroundColor: "black",
          }}
        >
          <CardMedia
            className={classes.root}
            image={item.image}
            sx={{
              width: item.width * 2,
              height: item.height,
              opacity: item.opacity,
            }}
          />
        </div>
        <div className={classes.desc}>
          <Typography className={classes.title} variant="h3" display="inline">
            {item.title}
          </Typography>
          <Typography className={classes.more} variant="body1">
            {item.desc}
          </Typography>
        </div>
      </CardActionArea>
    </div>
  );
}
