import React from "react";
import { Box, IconButton, Stack, Divider, CardMedia, Button, Grid, Typography, Link, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Icon } from "@iconify/react";
import sendIcon from "@iconify-icons/carbon/send";
import githubIcon from "@iconify-icons/logos/github-icon";
import telegramIcon from "@iconify-icons/logos/telegram";
import linkedinIcon from "@iconify-icons/logos/linkedin-icon";

import Amir from "../../assets/images/amir.png";
import { LINKED_IN_ADRESS, GITHUB_ADRESS, TELEGRAM_ADRESS } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {},
  desc: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "25.5%",
    marginTop: "35.5%",
  },
  profileImgContainer: {
    margin: "15%",
  },
  profileImg: {
    borderRadius: "inherit",
    // borderTopLeftRadius: 240,
    // borderTopRightRadius: 240,
    // borderBottomLeftRadius: 280,
    // borderBottomRightRadius: 280,
  },
  "@keyframes typing": {
    "0%": { width: "0%" },
    "100%": { width: "100%" },
  },
  "@keyframes blink-caret": {
    "0%": { borderColor: "transparent" },
    "100%": { borderColor: "transparent" },
    "50%": { borderColor: "orange" },
  },
  greetingText: {
    overflow: "hidden",
    borderRight: ".15em solid orange",
    whiteSpace: "nowrap",
    margin: "0 auto",
    // letterSpacing: ".15em",
    animation: "$typing 4.5s steps(40, end), $blink-caret 0.75s step-end infinite",
    // animation: "$typing 1s infinite",
  },
}));

export default function Home() {
  const classes = useStyles();
  return (
    <Box sx={{ ml: 8 }}>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box className={classes.desc}>
            <Box>
              <Typography variant="h3" className={classes.greetingText}>
                Hi, I am Amir Kheiru
              </Typography>
              <Typography variant="h5" gutterBottom color="textSecondary">
                FullStack developer
              </Typography>
              <Divider />
              <Typography variant="body1" color="textSecondary">
                High level experience in web app development and problem solving, producing high quality work.
              </Typography>
              <br />
              <Button variant="contained" endIcon={<Icon icon={sendIcon} width={24} height={24} />}>
                Contact Me
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box className={classes.profileImgContainer}>
            <Paper elevation={1} sx={{ borderRadius: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <CardMedia className={classes.profileImg} image={Amir} sx={{ m: 0.5, width: "100%", height: 400 }} />
            </Paper>
            <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 2, width: "100%" }}>
              <IconButton component={Link} href={LINKED_IN_ADRESS} target="_blank">
                <Icon icon={linkedinIcon} width={24} height={24} />
              </IconButton>
              <IconButton component={Link} href={GITHUB_ADRESS} target="_blank">
                <Icon icon={githubIcon} width={24} height={24} />
              </IconButton>
              <IconButton component={Link} href={TELEGRAM_ADRESS} target="_blank">
                <Icon icon={telegramIcon} width={24} height={24} />
              </IconButton>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
