import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Divider, Typography, Container, Stack, Box } from "@material-ui/core";

import Card from "../../components/surfaces/Card";
import algorithm from "../../assets/vectors/001-algorithm.svg";
import robot from "../../assets/vectors/002-robot.svg";
import webdesign from "../../assets/vectors/010-ui-design-1.svg";
import web from "../../assets/vectors/005-web.svg";
import intelligent from "../../assets/vectors/008-intelligent.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(8),
    height: theme.spacing(100),
  },
}));

const services = [
  { title: "Algorithm Design", image: algorithm },
  { title: "UI Design", image: webdesign },
  { title: "Web Development", image: web },
  { title: "Bot Development", image: robot },
  { title: "System Dev", image: intelligent },
];

export default function Servies() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Divider sx={{ mb: 10 }}>
        <Typography variant="h3" align="center">
          Services
        </Typography>
        <Typography variant="subtitle2" align="center" color="textSecondary">
          What do I offer?
        </Typography>
      </Divider>
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            // flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          {services.map((item, index) => (
            <div key={index} className={item.title} onAnimationIteration={() => console.log("boom")}>
              <script>animateDiv(item.title)</script>
              <Card title={item.title} image={item.image} index={index} />
            </div>
          ))}
        </Box>
      </Container>
    </div>
  );
}
