import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Icon } from "@iconify/react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link, animateScroll as scroll } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  customNavBtn: {
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "start",
    padding: theme.spacing(2),
    borderRadius: 0,
    width: theme.spacing(7),
    height: theme.spacing(6),
    transition: "width 0.5s",
    cursor: "pointer",
    textDecoration: "none",
  },
  container: {
    width: theme.spacing(16),
    height: theme.spacing(6),
    transition: "width 1s",
    transitionTimingFunction: "linear",

    "&:hover .btn": {
      color: theme.palette.primary.main,
      width: theme.spacing(18),
    },
  },
}));

export default function NavBtn({ icon, text, link }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Link to={`${link}`} smooth={true}>
        <Paper elevation={4} variant="elevation" className={clsx(["btn", classes.customNavBtn])}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div style={{ height: 24, width: 24 }}>
              <Box component={Icon} icon={icon} sx={{ display: "block", width: 24, height: 24 }} />
            </div>
            <Box>
              <Typography sx={{ ml: 2, fontWeight: 100, fontSize: "17px" }}>{text}</Typography>
            </Box>
          </div>
        </Paper>
      </Link>
    </div>
  );
}

NavBtn.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  link: PropTypes.string,
};
