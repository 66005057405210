import * as React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  CardAcitonArea,
  Typography,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    zIndex: 10,
  },
}));

export default function DetailCard({ item }) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Grid container>
        <Grid item xs={5}>
          <CardMedia
            component="img"
            height="140"
            sx={{ height: "100%" }}
            image={item.image}
            alt="green iguana"
          />
        </Grid>
        <Grid item xs={7}>
          <CardContent>
            <Typography gutterBottom variant="h3" component="div">
              {item.title}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {item.desc}
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}
