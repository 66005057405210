import React from "react";
import { makeStyles } from "@material-ui/styles";
import homeOutlined from "@iconify-icons/ant-design/home-outlined";
import userOutlined from "@iconify-icons/ant-design/user-outlined";
import documentIcon from "@iconify-icons/carbon/document";
import imageIcon from "@iconify-icons/carbon/image";
import cubeIcon from "@iconify-icons/carbon/cube";
import sendAlt from "@iconify-icons/carbon/send-alt";

import NavBtn from "./NavBtn";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    flexDirection: "column",
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    zIndex: 100,
    position: "fixed",
    // marginLeft: "auto",
  },
}));

export default function VerticalNavBar() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <NavBtn icon={homeOutlined} text="Home" link="home" />
      <NavBtn icon={userOutlined} text="About" link="about" />
      <NavBtn icon={documentIcon} text="Skills" link="skills" />
      <NavBtn icon={cubeIcon} text="Services" link="services" />
      <NavBtn icon={imageIcon} text="Portfolio" link="portfolio" />
      <NavBtn icon={sendAlt} text="Contact" />
    </div>
  );
}
