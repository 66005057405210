import VerticalNavBar from "./components/navigations/VerticalNavBar";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Skills from "./pages/skills/Skills";
import Services from "./pages/services/Services";
import ThemeConfig from "./theme";
import Portfolio from "./pages/portfolio/Portfolio";

function App() {
  return (
    <div className="App">
      <ThemeConfig>
        <VerticalNavBar />
        <div id="home">
          <Home />
        </div>
        <div id="about">
          <About />
        </div>
        <div id="skills">
          <Skills />
        </div>
        <div id="services">
          <Services />
        </div>
        <div id="portfolio">
          <Portfolio />
        </div>
      </ThemeConfig>
    </div>
  );
}

export default App;
