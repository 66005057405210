import codeBrackets from "@iconify-icons/icon-park/code-brackets";
import bareMetalServer from "@iconify-icons/carbon/bare-metal-server";
import guiIcon from "@iconify-icons/carbon/gui";
import codeIcon from "@iconify-icons/carbon/code";
import bxGitBranch from "@iconify-icons/bx/bx-git-branch";
import bxBot from "@iconify-icons/bx/bx-bot";
import bxData from "@iconify-icons/bx/bx-data";
import bxLineChart from "@iconify-icons/bx/bx-line-chart";

export const SKILLS = [
  {
    icon: codeBrackets,
    title: "Frontend Technology",
    years: "More than 3 years",
    details: [
      { name: "HTML", value: 90 },
      { name: "CSS", value: 90 },
      { name: "JavaScript", value: 75 },
      { name: "React", value: 85 },
    ],
  },
  {
    icon: bareMetalServer,
    title: "Backend Technology",
    years: "More than 3 years",
    details: [
      { name: "Python", value: 80 },
      { name: "Django", value: 60 },
      { name: "Django RESTframework", value: 50 },
    ],
  },
  {
    icon: guiIcon,
    title: "Design",
    years: "Less than 1 years",
    details: [{ name: "Figma", value: 40 }],
  },
  {
    icon: codeIcon,
    title: "Development Languages",
    years: "More than 4 years",
    details: [
      { name: "Python", value: 80 },
      { name: "C++", value: 75 },
      { name: "JavaScript", value: 75 },
      { name: "C", value: 55 },
    ],
  },
  {
    icon: bxData,
    title: "Database and Cache",
    years: "More than 2 years",
    details: [
      { name: "MySql", value: 70 },
      { name: "MongoDB", value: 50 },
    ],
  },
  {
    icon: bxGitBranch,
    title: "Version Control",
    years: "More than 2 years",
    details: [
      { name: "git", value: 65 },
      { name: "github", value: 70 },
    ],
  },
  {
    icon: bxLineChart,
    title: "Algorithm Design & Analysis",
    years: "More than 3 years",
    details: [
      { name: "Design", value: 80 },
      { name: "Analysis", value: 85 },
      { name: "Implementation", value: 80 },
    ],
  },
  {
    icon: bxBot,
    title: "Automation and bots",
    years: "More than 6 month",
    details: [
      { name: "Telegram Bot", value: 70 },
      { name: "Scripts", value: 60 },
    ],
  },
];
